import React from 'react'
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { FaBars, FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types'
import Logo from '../images/one_law_logo.svg'
import DarkLogo from "../images/one_law_logo_dark.svg"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      city: "Salt Lake City"
    }
    this.getCityFromIP();
  }

  getCityFromIP = () => {
    typeof window !== 'undefined' && window.fetch('https://get.geojs.io/v1/ip/geo.json')
    .then(response => response.json())
    .then(data => {
      if (data.region == "Utah") {
        this.setState( { city: data.city});
      }
    });
    
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      }
    );
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Logo/>
          </div>
          <div
            id="navMenu"
            className="navbar-menu"
          >
            <div className="navbar-start has-text-centered">
              <span className="all-caps" style={{textTransform: 'uppercase', fontWeight: 800}}>Personal Injury Attorneys</span>
              <span className="divider">|</span>
              <span ref="city">{this.state.city}</span>
              <span>, Utah</span>
            </div>
            <div className="navbar-end has-text-centered">
              <div className="links">
                <Link to="scroll-results" smooth={true}>
                  <span id="scroll-link-results" className="page-scroll-link">Results</span>
                </Link>
                <Link to="scroll-cases" smooth={true}>
                  <span id="scroll-link-results" className="page-scroll-link">Cases</span>
                </Link>
                <Link to="scroll-faq" smooth={true}>
                  <span id="scroll-link-results" className="page-scroll-link">FAQ</span>
                </Link>
                <Link to="scroll-how-it-works" smooth={true}>
                  <span id="scroll-link-results" className="page-scroll-link">How It Works</span>
                </Link>
                <Link to="scroll-contact" smooth={true}>
                  <span id="scroll-link-results" className="page-scroll-link">Contact</span>
                </Link>
                <div className="call-btn"><a href="tel:4353190000">Call us at 435-319-0000</a></div>
              </div>
              <div className="mobile-menu-bars" onClick={() => this.toggleHamburger()}>
                <FaBars/>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-actions-container">
         <div className="mobile-call-btn"><a href="tel:4353190000">Call us at 435-319-0000</a></div>
        </div>
        <div className={this.state.active ? "mobile-pop-menu show" : "mobile-pop-menu" }>
          <FaTimes class="close-btn" onClick={() => {
             this.toggleHamburger()
          }} />
          <DarkLogo class="dark-logo"/>
          <Link to="scroll-results" smooth={true} delay={300} onClick={() => {
             this.toggleHamburger()
          }}>
            <span id="scroll-link-results" className="page-scroll-link">Results</span>
          </Link>
          <Link to="scroll-cases" smooth={true} delay={300} onClick={() => {
             this.toggleHamburger()
          }}>
            <span id="scroll-link-results" className="page-scroll-link">Cases</span>
          </Link>
          <Link to="scroll-faq" smooth={true} delay={300} onClick={() => {
             this.toggleHamburger()
          }}>
            <span id="scroll-link-results" className="page-scroll-link">FAQ</span>
          </Link>
          <Link to="scroll-how-it-works" smooth={true} delay={300} onClick={() => {
             this.toggleHamburger()
          }}>
            <span id="scroll-link-results" className="page-scroll-link">How It Works</span>
          </Link>
          <Link to="scroll-contact" smooth={true} delay={300} onClick={() => {
             this.toggleHamburger()
          }}>
            <span id="scroll-link-results" className="page-scroll-link">Contact</span>
          </Link>
          <div className="call-to-action">
            <div className="subtitles">
              <a href="tel:800225515">
                <strong>Call us at 800-225-5152</strong>
              </a>
            </div>
            <div className="cta-btn" onClick={() => {
              this.toggleHamburger();
              this.props.ctaCallback != null && this.props.ctaCallback();
            } }>
              Get a Free Consultation
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

Navbar.propTypes = {
  ctaCallback: PropTypes.func
}

Navbar.defaultProps = {
  ctaCallback: null
}


export default Navbar
